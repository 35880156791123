<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 265px)"
      class="table-padding-2-no-top pl-md-5"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <InputFilter
                label="Mã cấu hình"
                placeholder="Mã cấu hình"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                label="Tên cấu hình"
                placeholder="Tên cấu hình"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.date')"
                :placeholder="$t('labels.date')"
                name="created_at"
                sort-name="created_at"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn small color="success" @click="showAddConfigDialog">{{
                $t("labels.add")
              }}</v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in configs"
            :key="`c_${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <span
                class="cursor-pointer text-decoration-underline primary--text"
                @click="showDetailConfig(item)"
              >
                {{ item.code }}
              </span>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="addConfigDialog" persistent max-width="800px">
      <WarningCreateConfig @cancel="hideAddConfigDialog" />
    </v-dialog>

    <v-dialog v-model="detailConfigDialog" persistent max-width="800px">
      <WarningDetailConfig :config="detail" @cancel="hideDetailConfig" />
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    WarningCreateConfig: () =>
      import("@/components/employee/WarningCreateConfig"),
    WarningDetailConfig: () =>
      import("@/components/employee/WarningDetailConfig"),
  },
  name: "WarningConfigTable",
  data: () => ({
    configs: [],
    owners: [],
    filters: {},
    isLoading: false,
    page: 1,
    warehouses: [],
    addConfigDialog: false,
    detailConfigDialog: false,
    detail: {},
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getWarehouses();
    this.getList();
  },
  methods: {
    showAddConfigDialog() {
      this.addConfigDialog = true;
    },
    hideAddConfigDialog() {
      this.addConfigDialog = false;
    },
    showDetailConfig(item) {
      this.detail = { ...item };
      this.detailConfigDialog = true;
    },
    hideDetailConfig() {
      this.detail = {};
      this.detailConfigDialog = false;
    },
    getList: debounce(function () {
      httpClient
        .post("/warning-config-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.configs = [...data.rows];
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getWarehouses() {
      const { warehouses } = window.me;
      this.warehouses = [...warehouses].map((w) => ({
        value: w.id,
        text: w.code_name || "",
      }));
    },
  },
};
</script>
